import {
  SortInfo,
  OutputInfo,
  Output,
  PartialMetricInfo,
  PartialBucketInfo,
  FieldMode,
} from './common';

export interface AgGridAttributes {
  numberOfValues: number;
  withQuickFilter: boolean;
  withExport: boolean;
  agTheme: AgGridTheme;
  showLast?: boolean;

}

export interface AgGridProperties {
  metrics: PartialMetricInfo[];
  bucket: PartialBucketInfo[];
  sort: SortInfo | null;
  rowGroup: RowGroup | null;
  colGroups: ColGroup[];
  customColNames: CustomColName[];
  pivotColumns: OutputInfo[] | null;
  fieldModes?: FieldMode[];
  rowGroupTotal: boolean; // Totals for each row group
  footerTotal: boolean; // Totals for all the metric columns
}

export interface RowGroup {
  outputs: OutputInfo[];
  name: string | null;
}

export interface ColGroup {
  outputs: ColOutputInfo[];
  name: string | null;
}

export enum ColGroupShow {
  Always = 'always',
  OnlyWhenCollapsed = 'collapsed',
  OnlyWhenExpanded = 'expanded',
}

export interface ColOutputInfo extends OutputInfo {
  show: ColGroupShow;
}

export interface CustomColName {
  output: Output;
  name: string;
}

export enum AgGridTheme {
  condensed = 'condensed',
  default = 'default',
}
