import { ColorString, Options, setOptions, CSSObject, DataLabelsOptionsObject } from 'highcharts';
import { CreateOptionsParams } from './api';
import { categoricalColors, getContrastTextColor, gradientColors } from './colors';

function getGradientIndexes(length: number): number[] {
  switch (length) {
    case 1:
      return [1];
    case 2:
      return [1, 7];
    case 3:
      return [1, 4, 7];
    case 4:
      return [0, 1, 4, 7];
    case 5:
      return [0, 1, 4, 7, 9];
    case 6:
      return [0, 1, 4, 6, 7, 9];
    case 7:
      return [0, 1, 4, 6, 7, 8, 9];
    case 8:
      return [0, 1, 2, 4, 6, 7, 8, 9];
    case 9:
      return [0, 1, 2, 3, 4, 6, 7, 8, 9];
    default:
      return gradientColors.map((val, index) => index);
  }
}

// from https://sbc.safe.socgen/docs/DOC-828088?et=watches.email.document
export function createColors(nbColors: number): ColorString[] {
  // sgdashboard.customColor is xlD variable
  const activeCustomColor: string =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)._environment != null ? (window as any)._environment.customcolor : null;
  const customColor: string = activeCustomColor ? activeCustomColor : 'no';
  // webpack hack https://stackoverflow.com/questions/28572380/conditional-build-based-on-environment-using-webpack
  // has to put to lowercase otherwise, webpack will exclude the code conditions
  if (customColor.toLowerCase() === 'yes') {
    return getCategoricalPalette(nbColors);
  } else {
    return getGradientPalette(nbColors);
  }
}

export function getGradientPalette(length: number): ColorString[] {
  const indexes = getGradientIndexes(length);
  return indexes.map(index => gradientColors[index]);
}

export function getCategoricalPalette(length: number): ColorString[] {
  return categoricalColors.slice(0, length).map(c => c.hex);
}

export interface Colors {
  primary: string;
  secondary: string;
  bgLvl1: string;
  light: string;
}

// styling specs: https://sbc.safe.socgen/docs/DOC-828088?et=watches.email.document
export const createOptionsFactory = ({
  primary: primaryColor,
  secondary: secondaryColor,
  bgLvl1: bgLvl1Color,
  light: lightColor
}: Colors) => ({ nbSeries }: CreateOptionsParams): Options => {
  const fontSizeValue = 14;
  const fontSize = `${fontSizeValue}px`;
  const fontWeight = '500';
  const legendEnabled = nbSeries > 1;
  const labelStyle: CSSObject = {
    color: primaryColor,
    textOutline: 'none',
    fontSize,
    fontWeight
  };
  // Used for charts which show the data labels inside the bar/column
  const stackedChartDataLabels: DataLabelsOptionsObject = {
    style: labelStyle,
    formatter: function(this): string {
      const contrastText = getContrastTextColor(this.point.color as string);
      if (contrastText) {
        return `<span style="color: ${contrastText};">${this.y}</span>`;
      }
      return `<span>${this.y}</span>`;
    }
  };

  const axis = {
    labels: { style: { fontSize, color: secondaryColor } },
    gridLineColor: lightColor
  };
  // global options
  // - all the properties below do not work if not set globally (i.e. for all the charts of the page)
  // e.g. "https://www.highcharts.com/forum/viewtopic.php?t=7096"
  // - setOptions() is called each time createOptions() is called in order to reset global options potentially configured by other webcomponents
  setOptions({
    chart: {
      style: {
        fontFamily: '"Inter var", sans-serif',
        fontSize
      }
    },
    xAxis: axis,
    yAxis: axis,
    lang: {
      thousandsSep: ',',
      decimalPoint: '.',
      numericSymbols: [' K', ' Mln', ' Bln']
    }
  });
  //
  // local options
  const options: Options = {
    credits: { enabled: false },
    title: {
      text: undefined
    },
    chart: {
      spacingTop: (legendEnabled ? 1 : 1.5) * fontSizeValue, // m-3 / m-4
      backgroundColor: bgLvl1Color
    },
    xAxis: {
      dateTimeLabelFormats: {
        day: `%a` // "Mon"  https://api.highcharts.com/gantt/xAxis.dateTimeLabelFormats
      }
    },
    yAxis: {
      title: {
        text: undefined
      },
      stackLabels: {
        style: labelStyle
      }
    },
    tooltip: {
      backgroundColor: bgLvl1Color,
      borderWidth: 0,
      shared: true,
      shape: 'square',
      /* @TODO:
      remove all the customizations that depend on 'point': they are not meaningfull for all chart types (e.g a wordcount chart needs to use point.weight instead of point.y)
      Ahmed decided to keep them for now
      */
      headerFormat: `<span style="color: ${secondaryColor};">{point.key}</span><br/>`,
      pointFormat: `
<span style="color:{point.color}">■</span>
<span style="color: ${primaryColor};"> {series.name}: </span>
<span style="color: ${primaryColor};font-weight:bold;">{point.y}</span><br/>`,
      style: { fontSize }
    },
    legend: {
      enabled: legendEnabled,
      align: 'left',
      verticalAlign: 'top',
      symbolRadius: 0,
      itemStyle: {
        color: secondaryColor,
        fontSize,
        fontWeight
      },
      margin: 1.5 * fontSizeValue // m-4
    },
    plotOptions: {
      column: {
        dataLabels: stackedChartDataLabels
      },
      bar: {
        dataLabels: stackedChartDataLabels
      },
      series: {
        dataLabels: {
          style: labelStyle
        }
      }
    },
    exporting: {
      buttons: {
        contextButton: {
          enabled: false
        }
      }
    }
  };
  return options;
};
