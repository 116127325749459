import * as Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import treemap from 'highcharts/modules/treemap';
import drilldown from 'highcharts/modules/drilldown';
import groupedCategories from 'highcharts-grouped-categories';
import solidGauge from 'highcharts/modules/solid-gauge';
import sankey from 'highcharts/modules/sankey';
import heatmap from 'highcharts/modules/heatmap';
import bullet from 'highcharts/modules/bullet';
import wordcloud from 'highcharts/modules/wordcloud';
import tilemap from 'highcharts/modules/tilemap';
import sunburst from 'highcharts/modules/sunburst';
import map from 'highcharts/modules/map';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import gantt from 'highcharts/modules/gantt';
import org from 'highcharts/modules/organization';
import accessibility from 'highcharts/modules/accessibility';

/*
@TODO
instead of adding all the needed plugins inside the generic widget (which increases the payload)
SGWT framework should provide a way to load those plugins dynamically through an import() (it currently does not work out of the box)
each instance of a generic <arclab-highcharts> widget could then list the plugins it needs in its sg-dashboard's metadata
see https://sgithub.fr.world.socgen/DT-Widgets/sg-widgets-framework/issues/24#issuecomment-398566
*/
[
  highchartsMore,
  treemap,
  drilldown,
  groupedCategories,
  sankey,
  solidGauge,
  heatmap,
  bullet,
  wordcloud,
  tilemap,
  sunburst,
  map,
  exporting,
  exportData,
  gantt,
  org,
  accessibility
].forEach(func => func(Highcharts));
