import PptxGenJS from 'pptxgenjs';

export enum WidgetExportType {
  Table = 'table',
  Text = 'text',
  Chart = 'chart',
}

export interface TextExport {
  type: WidgetExportType.Text;
  data: PptxGenJS.IText[];
}

export interface TableExport {
  type: WidgetExportType.Table;
  data: PptxGenJS.TableRow[];
  options: PptxGenJS.ITableOptions;
}

export interface ChartExport {
  type: WidgetExportType.Chart;
  chartType: PptxGenJS.CHART_NAME;
  data: any[];
  options: PptxGenJS.IChartOpts;
}

export type WidgetPptExportTypes = TableExport | TextExport | ChartExport;

export type PptExport = () => Promise<WidgetPptExportTypes>;
