import { useState, useEffect } from 'react';
import { useWidgetConfiguration } from '@sg-widgets/react-core';
import { useTriggerEvent } from './useTriggerEvent';
import { SgDashboardProps } from '../common/SgDashboard';
import { JsonValue } from './Json';
import { TriggerEvent } from 'sg-dashboard-sdk';

export function useLastSeqNum({
  inputData,
  logKey,
  emitEvent,
  userSelection,
  setUserSelection
}: SgDashboardProps): { triggerEvent: TriggerEvent; userSelectionRef: React.MutableRefObject<JsonValue> } {
  const conf = useWidgetConfiguration();
  const [lastSeqNum, setLastSeqNum] = useState(0);
  const { triggerEvent, userSelectionRef } = useTriggerEvent(conf, logKey, emitEvent, userSelection, setUserSelection);
  useEffect(() => {
    const { seqNum } = inputData;
    if (seqNum <= lastSeqNum) return;
    //
    triggerEvent({ requestSeqNum: seqNum }, userSelection);
    setLastSeqNum(seqNum);
  }, [inputData, lastSeqNum, userSelection, triggerEvent]);
  return { triggerEvent, userSelectionRef };
}
