import React, { useMemo } from 'react';
import {
  chart as createChart,
  ganttChart as createGanttChart,
  mapChart as createMapChart,
  Options,
  Chart
} from 'highcharts';

import { stockChart as createStockChart } from 'highcharts/highstock';

import { widgetize, WidgetPropsMapping, useWidgetConfiguration } from '@sg-widgets/react-core';
import { useRef, useEffect, useState } from 'react';
import { useAsyncControl } from '../common/AsyncControl';
import {
  createSgDashboardPropsMapping,
  checkSgDashboardProps,
  createPptExportPropsMapping
} from '../common/SgDashboard';
import { createColors, getGradientPalette, getCategoricalPalette, Colors, createOptionsFactory } from './styling';
import { Props, HighchartsDataVizType } from './api';
import { UserActionRequiredError, RequestAccessError } from '../common/Errors';
import { useEmptyStates, ErrorMsg } from '../common/EmptyStates';
import { Loading } from '../common/Loading';
import { merge } from 'lodash';
import { useLastSeqNum } from '../common/useLastSeqNum';
import { PptExport } from 'sg-dashboard-sdk';
import './plugins';
import { asStringInitializedEnum } from '../common/Sgwt';
export const tagName = 'arclab-highcharts';

export const ArclabHighcharts: React.FC<Props> = props => {
  const {
    inputData,
    logKey,
    query,
    sgConnectEnvironment,
    theme,
    getArcLabPptExport,
    setPptExport,
    highchartsDataviz = HighchartsDataVizType.Chart,
    widgetProperties,
    widgetAttributes
  } = props;
  checkSgDashboardProps(props, 'ArclabHighcharts');
  if (query === undefined) throw new Error(`ArclabHighcharts key[${logKey}] missing property[query]`);
  //
  const conf = useWidgetConfiguration();
  const ref = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<Options | null>(null);
  const { start: asyncStart, stop: asyncStop, cleanup } = useAsyncControl(logKey);
  const {
    start: emptyStatesStart,
    stop: emptyStatesStop,
    loading,
    errorMsg,
    userActionNeeded,
    param,
    requestAccessNeeded
  } = useEmptyStates();
  const createOptions = useMemo(() => {
    /*
    the 'powerpoint export' feature does not work if a CSS variable (e.g. var(--primary)) is used in highcharts SVG (i.e. in highcharts Options)
    getComputedStyle() is used to get the value of those variables
    */
    const style = getComputedStyle(document.documentElement);
    const primary = style.getPropertyValue('--primary');
    const secondary = style.getPropertyValue('--secondary');
    const bgLvl1 = style.getPropertyValue('--bg-lvl1');
    const light = style.getPropertyValue('--light');
    const colors: Colors = { primary, secondary, bgLvl1, light };
    /*
    Rq: the useMemo() function is sometimes called twice but address of the returned 'createOptions' only changes once
    cf https://github.com/facebook/react/issues/14413
    */
    conf.debug(`createOptionsFactory() key[${logKey}] theme[${theme}] colors[${JSON.stringify(colors)}]`);
    return createOptionsFactory(colors);
  }, [conf, logKey, theme /* 'theme' must remain a dependency for the colors to be updated correctly */]);
  const { triggerEvent, userSelectionRef } = useLastSeqNum(props);
  useEffect(() => {
    async function createOpts(): Promise<void> {
      emptyStatesStart();
      const ctl = asyncStart();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { seqNum, ...inputs } = inputData;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let optionsAny: any = null;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let error: any = null;
      try {
        conf.debug(`createOpts() key[${logKey}] start`);
        optionsAny = await query({
          createOptions,
          createColors,
          getGradientPalette,
          getCategoricalPalette,
          widgetConfiguration: conf,
          logKey,
          inputData: inputs,
          UserActionRequiredError,
          RequestAccessError,
          merge,
          theme,
          sgConnectEnvironment,
          triggerEvent,
          userSelection: userSelectionRef.current,
          attributes: widgetAttributes,
          properties: widgetProperties
        });
        conf.debug(`createOpts() key[${logKey}] stop items[${JSON.stringify(optionsAny)}]`);
      } catch (err) {
        conf.error(`createOpts() key[${logKey}] stop error[${err}]`);
        error = err;
      }
      if (asyncStop(ctl)) return;
      if (emptyStatesStop(error)) {
        setOptions(null); // needed to call Chart.destroy()
        return;
      }
      //
      if (
        optionsAny === null || // typeof(null) returns 'object' (historical JS bug)
        typeof optionsAny !== 'object'
      ) {
        throw new Error(
          `ArclabHighcharts createOpts() key[${logKey}] options[${JSON.stringify(optionsAny)}] must be an object`
        );
      }
      const options = optionsAny as Options;
      setOptions(options);
    }
    createOpts();
    return cleanup;
  }, [
    query,
    cleanup,
    conf,
    inputData,
    logKey,
    asyncStart,
    asyncStop,
    emptyStatesStart,
    emptyStatesStop,
    theme,
    sgConnectEnvironment,
    createOptions,
    triggerEvent,
    userSelectionRef,
    widgetProperties,
    widgetAttributes
  ]);
  useEffect(() => {
    const { current: el } = ref;
    if (!el || !options) return;
    let chart: Chart;
    switch (highchartsDataviz) {
      case HighchartsDataVizType.Gantt:
        chart = createGanttChart(el, options);
        break;
      case HighchartsDataVizType.WorldMap:
        chart = createMapChart(el, options);
        break;
      case HighchartsDataVizType.Stock:
        chart = createStockChart(el, options);
        break;
      default:
        chart = createChart(el, options);
        break;
    }
    return () => {
      chart.destroy();
    };
  }, [options, highchartsDataviz]);
  useEffect(() => {
    if (!getArcLabPptExport || !options || !setPptExport) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { seqNum, ...inputs } = inputData;
    const getPptExport: PptExport = () =>
      getArcLabPptExport({
        options,
        widgetConfiguration: conf,
        logKey,
        inputData: inputs,
        theme,
        sgConnectEnvironment
      });
    setPptExport(getPptExport);
  }, [getArcLabPptExport, setPptExport, options, conf, logKey, inputData, theme, sgConnectEnvironment]);
  //
  if (errorMsg)
    return (
      <ErrorMsg
        msg={errorMsg}
        userActionNeeded={userActionNeeded}
        param={param}
        requestAccessNeeded={requestAccessNeeded}
      />
    );
  if (loading) return <Loading />;
  return <div data-testid={tagName} ref={ref} style={{ width: '100%', height: '100%' }}></div>;
};

if (window.customElements && !window.customElements.get(tagName)) {
  widgetize(tagName, ArclabHighcharts, {
    ...createSgDashboardPropsMapping('output-event'),
    ...createPptExportPropsMapping(),
    query: WidgetPropsMapping.asObject(),
    getArcLabPptExport: WidgetPropsMapping.asObject(),
    widgetProperties: WidgetPropsMapping.asObject(),
    widgetAttributes: WidgetPropsMapping.asObject(),
    highchartsDataviz: asStringInitializedEnum<Props, HighchartsDataVizType>()
  });
}
