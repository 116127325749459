import { JsonObject, JsonValue } from 'Json';

export enum SgConnectEnvironment {
  Homologation = 'homologation',
  Production = 'production',
}

export enum BootstrapTheme {
  Standard = 'standard',
  Dark = 'dark',
}

export interface SgDashboardWidgetQueryParams extends SgDashboardQueryParams {
  RequestAccessError: Constructor<RequestAccessError>;
  UserActionRequiredError: Constructor<UserActionRequiredError>;
  userSelection: JsonValue;
  triggerEvent: TriggerEvent;
  attributes: JsonObject | null;
  properties: JsonObject | null;
}

export type TriggerEvent = (
  detail: JsonObject,
  configuration: JsonValue
) => void;

export interface SgDashboardQueryParams {
  widgetConfiguration: any;
  logKey: string;
  inputData: JsonObject;
  theme: BootstrapTheme;
  sgConnectEnvironment: SgConnectEnvironment;
}

interface RequestAccessErrorInput {
  message?: string;
  redirectUri: string;
}
export class RequestAccessError extends Error {
  public redirectUri: string;
  constructor({ message = '', redirectUri }: RequestAccessErrorInput) {
    super(message);
    this.redirectUri = redirectUri;
  }
}

export class UserActionRequiredError extends Error {
  constructor(message: string) {
    super(message);
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Constructor<T> = new (...args: any[]) => T;
