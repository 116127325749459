import { Options, ColorString } from 'highcharts';
import {
  SgDashboardProps,
  SgDashboardWidgetQueryParams,
  SgDashboardQueryParams,
  PptExportProps
} from '../common/SgDashboard';
import { HighchartsType, JsonObject, WidgetPptExportTypes } from 'sg-dashboard-sdk';

export interface Props extends SgDashboardProps, PptExportProps {
  query: Query;
  getArcLabPptExport?: (params: PptExportParams) => Promise<WidgetPptExportTypes>;
  highchartsDataviz?: HighchartsDataVizType;
  widgetProperties: JsonObject | null;
  widgetAttributes: JsonObject | null;
}

export type Query = (params: QueryParams) => Promise<Options>;

export interface QueryParams extends SgDashboardWidgetQueryParams {
  createOptions: (params: CreateOptionsParams) => Options;
  createColors: (nbColors: number) => ColorString[];
  getCategoricalPalette: (nbColors: number) => ColorString[];
  getGradientPalette: (nbColors: number) => ColorString[];
  merge<TObject, TSource>(object: TObject, source: TSource): TObject & TSource;
}

export interface CreateOptionsParams {
  type: HighchartsType;
  nbSeries: number;
}

interface PptExportParams extends SgDashboardQueryParams {
  options: Options;
}

export enum HighchartsDataVizType {
  Chart = 'chart',
  Gantt = 'gantt',
  WorldMap = 'worldMap',
  Stock = 'stock'
}
