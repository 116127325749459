import React from 'react';

interface Props {
  className?: string;
}

export const Loading: React.FC<Props> = ({ className }) => {
  return (
    <div className={`spinner-grow spinner-grow-sm ${className}`} role="status">
      Loading...
    </div>
  );
};
