import { MetricInfo, PartialMetricInfo } from './common';

export interface KpiAttributes {
  size: WidgetSize;
  showFirstKpiHeader: boolean;
}

export type KpiProperties = {
  kpis: PartialKpiInfo[];
};

export type PartialKpiInfo = {
  value: PartialMetricInfo;
  header?: PartialKpiHeaderInfo;
  footer?: PartailKpiFooterInfo;
};

export enum WidgetSize {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export interface KpiInfo {
  value: MetricInfo;
  header?: KpiHeaderInfo;
  footer?: KpiFooterInfo;
}

export interface PartialKpiHeaderInfo {
  text: string;
  metric?: PartialMetricInfo; // the occurences of the substring '{value}' in 'text' will be replaced with the 'metric' value
  tooltip?: string;
}

export interface KpiHeaderInfo {
  text: string;
  metric?: MetricInfo; // the occurences of the substring '{value}' in 'text' will be replaced with the 'metric' value
  tooltip?: string;
}

export interface PartailKpiFooterInfo {
  text: string;
  metric?: PartialMetricInfo; // the occurences of the substring '{value}' in 'text' will be replaced with the 'metric' value
}

export interface KpiFooterInfo {
  text: string;
  metric?: MetricInfo; // the occurences of the substring '{value}' in 'text' will be replaced with the 'metric' value
}

export const valueRegExp = '{value}';
