import { useRef, useEffect, useCallback } from 'react';
import { useWidgetConfiguration } from '@sg-widgets/react-core';

export interface AsyncControl {
  cancel: boolean;
}

export function createAsyncControl(): AsyncControl {
  return { cancel: false };
}

export function useAsyncControl(
  logKey: string
): { start: () => AsyncControl; stop: (ctl: AsyncControl) => boolean; cleanup: () => void } {
  const control = useRef<AsyncControl | null>(null);
  const conf = useWidgetConfiguration();
  const cleanup = useCallback(() => {
    const { current: ctl } = control;
    if (ctl) {
      conf.debug(`useAsyncControl() cleanup key[${logKey}] cancel`);
      ctl.cancel = true;
      control.current = null;
    }
  }, [conf, logKey]);
  useEffect(() => {
    return cleanup; // didUnmount
  }, [cleanup]);
  const start = useCallback(() => {
    if (control.current) {
      throw new Error(
        `useAsyncControl() start key[${logKey}] invalid state control[${JSON.stringify(control.current)}]`
      );
    }
    const ctl = createAsyncControl();
    control.current = ctl;
    return ctl;
  }, [logKey]);
  const stop = useCallback(
    (ctl: AsyncControl) => {
      if (ctl.cancel) {
        conf.debug(`useAsyncControl() stop key[${logKey}] control[${JSON.stringify(ctl)}]`);
        return true;
      }
      control.current = null;
      return false;
    },
    [conf, logKey]
  );
  return { start, stop, cleanup };
}
