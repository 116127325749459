import { useCallback, useRef } from 'react';
import { WidgetConfiguration } from '@sg-widgets/shared-core';
import { JsonObject, JsonValue } from './Json';
import { TriggerEvent } from 'sg-dashboard-sdk';

export function useTriggerEvent(
  conf: WidgetConfiguration,
  logKey: string,
  emitEvent: (data: JsonObject) => void,
  userSelection: JsonValue,
  setUserSelection: (value: JsonValue) => void
): { triggerEvent: TriggerEvent; userSelectionRef: React.MutableRefObject<JsonValue> } {
  const userSelectionRef = useRef(userSelection);
  const triggerEvent = useCallback<TriggerEvent>(
    (detail: JsonObject, configuration: JsonValue): void => {
      conf.debug(
        `emitEvent() key[${logKey}] detail[${JSON.stringify(detail)}] configuration[${JSON.stringify(configuration)}]`
      );
      userSelectionRef.current = configuration;
      setUserSelection(configuration);
      emitEvent(detail);
    },
    [conf, logKey, emitEvent, setUserSelection, userSelectionRef]
  );
  return { triggerEvent, userSelectionRef };
}
