import { JsonObject, JsonValue } from './Json';
import { InputData } from './InputData';
import { WidgetPropsMapping } from '@sg-widgets/react-core';
import { BootstrapTheme, SgConnectEnvironment, PptExport } from 'sg-dashboard-sdk';
import { asStringInitializedEnum } from './Sgwt';

export { BootstrapTheme, SgConnectEnvironment } from 'sg-dashboard-sdk';

export const DEFAULT_KEY_NAME = 'keyName';

export interface SgDashboardProps {
  userSelection: JsonValue;
  setUserSelection: (value: JsonValue) => void;
  emitEvent: (data: JsonObject) => void;
  inputData: InputData; // inputDataProperty must be set to "inputData" in the metadata and eventWhenInputProcessed must be set to true
  logKey: string; // log identifier
  theme: BootstrapTheme;
  sgConnectEnvironment: SgConnectEnvironment;
  widgetAttributes: JsonObject | null;
  widgetProperties: JsonObject | null;
}

export interface SgDashboardFilterProps extends SgDashboardProps {
  keyName: string; // to trigger an event with a payload: { [keyName]: <value> }
}

export interface PptExportProps {
  getPptExport?: PptExport; // set by the widget itself if getArcLabPptExport() is provided (by calling setPptExport())
  setPptExport?: (value: PptExport) => void;
}

export function createSgDashboardPropsMapping(
  eventType: string,
  exampleEvent?: any
): WidgetPropsMapping<SgDashboardProps> {
  return {
    userSelection: WidgetPropsMapping.asObject({
      description: `Input configuration used to control the component`
    }),
    setUserSelection: WidgetPropsMapping.asPropertySetter('userSelection'),
    emitEvent: WidgetPropsMapping.asEventEmitter(eventType, {
      description: `Event emitted as <code>widget-name--${eventType}</code> ${exampleEvent &&
        'eg:'} <pre>${exampleEvent && JSON.stringify(exampleEvent, null, 2)}</pre>`
    }),
    inputData: WidgetPropsMapping.asObject({
      description: `Incoming JSON object with technical field <code>requestSeqNum</code> for internal processing`
    }),
    logKey: WidgetPropsMapping.asString({ description: 'Key name used in logs' }),
    theme: asStringInitializedEnum<SgDashboardProps, BootstrapTheme>({
      description: `<code>${BootstrapTheme.Standard}</code> || <code>${BootstrapTheme.Dark}</code>`
    }),
    sgConnectEnvironment: asStringInitializedEnum<SgDashboardProps, SgConnectEnvironment>({
      description: `<code>${SgConnectEnvironment.Homologation}</code> || <code>${SgConnectEnvironment.Production}</code>`
    }),
    widgetAttributes: WidgetPropsMapping.asObject({
      description: 'Incoming Json object that contains widget attributes'
    }),
    widgetProperties: WidgetPropsMapping.asObject({
      description: 'Incoming Json object that contains widget properties'
    })
  };
}

export function createSgDashboardFilterPropsMapping(
  eventType: string,
  exampleEvent?: any
): WidgetPropsMapping<SgDashboardFilterProps> {
  return {
    ...createSgDashboardPropsMapping(eventType, exampleEvent),
    keyName: WidgetPropsMapping.asString({
      description: "Key name that will be used in the widgets' inputData to access this filter's outputs"
    })
  };
}

export function createPptExportPropsMapping(): WidgetPropsMapping<PptExportProps> {
  return {
    getPptExport: WidgetPropsMapping.asObject(),
    setPptExport: WidgetPropsMapping.asPropertySetter('getPptExport')
  };
}

export function checkSgDashboardProps(
  { userSelection, setUserSelection, emitEvent, inputData, logKey, theme, sgConnectEnvironment }: SgDashboardProps,
  label: string
): void {
  if (logKey === undefined) throw new Error(`${label} missing property[logKey]`);
  if (userSelection === undefined) throw new Error(`${label} key[${logKey}] missing property[userSelection]`);
  if (setUserSelection === undefined) throw new Error(`${label} key[${logKey}] missing property[setUserSelection]`);
  if (emitEvent === undefined) throw new Error(`${label} key[${logKey}] missing property[emitEvent]`);
  if (inputData === undefined) throw new Error(`${label} key[${logKey}] missing property[inputData]`);
  if (theme === undefined) throw new Error(`${label} key[${logKey}] missing attribute[theme]`);
  if (sgConnectEnvironment === undefined) {
    throw new Error(`${label} key[${logKey}] missing attribute[sg-connect-environment]`);
  }
}

export function checkSgDashboardFilterProps(props: SgDashboardFilterProps, label: string): void {
  const { keyName, logKey } = props;
  checkSgDashboardProps(props, label);
  if (keyName === undefined) throw new Error(`${label} key[${logKey}] missing attribute[key-name]`);
}

export type FilterItemKey = string;

export interface FilterItem {
  key: FilterItemKey; // used for the userSelection (i.e. persisted configuration). must be unique
  label: string;
  value: JsonValue; // used for the event
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function checkFilterItems(itemsAny: any, label: string, logKey: string): FilterItem[] {
  if (!Array.isArray(itemsAny)) {
    throw new Error(`${label} key[${logKey}] items must be an array type[${typeof itemsAny}]`);
  }
  const items = itemsAny as FilterItem[];
  const s = new Set<FilterItemKey>(items.map(({ key }) => key));
  if (s.size !== items.length) {
    throw new Error(`${label} key[${logKey}] items contains duplicate keys count[${items.length - s.size}]`);
  }
  return items;
}

export { SgDashboardQueryParams, SgDashboardWidgetQueryParams } from 'sg-dashboard-sdk';
