import { PropMappingType } from '@sg-widgets/react-core';

//******************************************************************************
// start from @sg-widgets/react-core/src/props/property.ts
//******************************************************************************
type PropertyOptions = {
  description: string;
  attribute: boolean;
};

export interface PropToPropertyMapping<TProps, TProp> {
  mappingType: PropMappingType.Property;
  property: {
    name?: string;
    description: string;
    coerce(propertyValue: unknown): TProp;
  };
  attribute?: {
    name?: string;
    deserialize: (attributeValue: string) => TProp;
  };
}

const DEFAULT_OPTIONS: PropertyOptions = {
  description: '',
  attribute: true
};

export function asType<TProps, TResult>(
  coerce: (propertyValue: unknown) => TResult,
  deserialize: (attributeValue: string) => TResult,
  options?: Partial<PropertyOptions>
): PropToPropertyMapping<TProps, TResult> {
  const opts = { ...DEFAULT_OPTIONS, ...options };
  return {
    mappingType: PropMappingType.Property,
    property: {
      description: opts.description,
      coerce
    },
    attribute: opts.attribute
      ? {
          deserialize
        }
      : undefined
  };
}
//******************************************************************************
// stop from @sg-widgets/react-core/src/props/property.ts
//******************************************************************************

export const asStringInitializedEnum = <TProps, TEnum>(
  options?: Partial<PropertyOptions>
): PropToPropertyMapping<TProps, TEnum> =>
  asType(
    (str: unknown) => str as TEnum,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (str: string) => (str as any) as TEnum,
    options
  );

export enum Environment {
  Development = 'development',
  Homologation = 'homologation',
  Production = 'production'
}
