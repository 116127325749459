import {
  SortInfo,
  BucketInfoItem,
  PartialBucketInfo,
  PartialMetricInfo,
  FieldMode,
} from './common';
import { CustomColName } from '..';

export enum HighchartsType {
  Column = 'column',
  Bar = 'bar',
  Pie = 'pie',
  Line = 'line',
}
export enum HighchartsV10Type {
  Arcdiagram = 'arcdiagram',
  Column = 'column',
  Bar = 'bar',
  Pie = 'pie',
  Line = 'line',
  treegraph = 'treegraph',
}
export interface HighchartsAttributes {
  type: HighchartsType;
  numberOfValues: number;
  customSerieName?: string;
  xAxisName?: string;
  yAxisName?: string;
  showTotals?: boolean;
  showSubTotals?: boolean;
  showLast?: boolean;
  showValues?: boolean;
}

export enum HighchartsColumnType {
  Basic = 'basic',
  Stacked = 'stacked',
  Grouped = 'grouped',
}

export enum HighchartsBarType {
  Basic = 'basic',
  Stacked = 'stacked',
  Grouped = 'grouped',
}

export interface HighchartsProperties {
  metrics: PartialMetricInfo[];
  bucket: PartialBucketInfo[];
  sort: SortInfo | null;
  specific: HighchartsSpecificProperties; // need to use a sub-object because all the HighchartsProperties fields are flattened into the webcomponent attributes/properties
  customLinesNames?: CustomColName[];
  dimension?: BucketInfoItem;
  fieldModes?: FieldMode[];
  showValues?: boolean;
  colorPalette?: ColorPaletteType;
}

export interface HighchartsColumnProperties {
  type: HighchartsType.Column;
  subType: HighchartsColumnType;
}
export interface HighchartsBarProperties {
  type: HighchartsType.Bar;
  subType: HighchartsBarType;
}
export interface HighchartsPieProperties {
  type: HighchartsType.Pie;
}
export interface HighchartsLineProperties {
  type: HighchartsType.Line;
}
export type HighchartsSpecificProperties =
  | HighchartsColumnProperties
  | HighchartsBarProperties
  | HighchartsPieProperties
  | HighchartsLineProperties;

export enum ColorPaletteType {
  Gradient = 'gradient',
  Categorical = 'categorical',
}
