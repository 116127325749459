export type FieldId = string;

export enum FieldType {
  METRIC = 'metric',
  BUCKET = 'bucket',
  DATE = 'date',
  URL = 'url',
}

export enum MetricType {
  Count = 'count',
  Sum = 'sum',
  Avg = 'avg',
  Min = 'min',
  Max = 'max',
  First = 'first',
  Last = 'last',
  Median = 'median',
}
export const numericMetricType = [
  MetricType.Sum,
  MetricType.Avg,
  MetricType.Min,
  MetricType.Max,
  MetricType.First,
  MetricType.Last,
  MetricType.Median,
] as const;

export declare type NumericMetricType = typeof numericMetricType[number];
export interface CountMetric {
  type: MetricType.Count;
  field: null;
}

export enum NumericMetricDisplay {
  Regular = 'regular',
  Percentage = 'percentage',
}
export interface NumericMetric {
  type: NumericMetricType;
  field: FieldId;
  digitsNb?: number;
  discardNullValueInAggregations?: boolean;
  displayAs?: NumericMetricDisplay;
}

export type Metric = CountMetric | NumericMetric;
export interface MetricInfo {
  data: Metric;
  label: string;
  sortable: boolean;
  digitsNb: number;
  discardNullValueInAggregations: boolean;
}

export type BucketItem = FieldId;
export type Bucket = BucketItem[];
export interface BucketInfoItem {
  data: BucketItem;
  label: string;
  sortable: boolean;
  type: FieldType;
}

export type BucketInfo = BucketInfoItem[];

export enum BiWebComponent {
  Highcharts = 'arclab-bi-highcharts',
  Picker = 'arclab-bi-picker',
  Select = 'arclab-bi-select',
  AgGrid = 'arclab-bi-ag-grid',
  Kpi = 'arclab-bi-kpi',
  SinglePicker = 'arclab-bi-single-picker',
}

export enum ExternalComponent {
  DateTimePicker = 'sgwt-datetime-picker',
}

export type SgwtComponent = BiWebComponent | ExternalComponent;

export const filterOutputEventSuffix = 'value-updated';
export const dateTimeFilterOutputEventSuffix = 'datetime-updated';
export enum DateMode {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  QUARTER = 'QUARTER',
  DAY = 'DAY',
  DAYDATE = 'DAYDATE',
  SEMESTER = 'SEMESTER',
  WEEK = 'WEEK',
}
export interface FieldMode {
  id: string;
  dateModes: DateMode[];
}
export interface SortInfo {
  type: SortType;
  output: Output;
}

export enum SortType {
  Ascending = 'asc',
  Descending = 'desc',
}

export enum OutputType {
  Bucket = 'bucket',
  Metric = 'metric',
  Date = 'date',
  Url = 'url',
}

export type Output = BucketOutput | MetricOutput | DateOutput | UrlOutput;
export interface BucketOutput {
  fieldType: OutputType.Bucket;
  field: BucketItem;
}
export interface DateOutput {
  fieldType: OutputType.Date;
  field: BucketItem;
}
export interface UrlOutput {
  fieldType: OutputType.Url;
  field: BucketItem;
}
export interface BucketOutput {
  fieldType: OutputType.Bucket;
  field: BucketItem;
}
export interface MetricOutput {
  fieldType: OutputType.Metric;
  field: string | null;
  type: 'count' | null;
  metric: Metric;
}
export interface OutputInfo {
  label: string;
  data: Output;
}

export const SUB_OBJECT_SEPARATOR = '->';

export interface PartialMetricInfo {
  label: string;
  data: { field: string | null; type: MetricType };
}

export interface PartialBucketInfo {
  label: string;
  data: FieldId;
  sortable: boolean;
}

export enum FilterType {
  Picker = 'picker',
  Select = 'select',
  SinglePicker = 'single-picker',
  DateTimePicker = 'datetime-picker',
}

export interface Field {
  id: FieldId; // unique for a given source
  label: string; // unique for a given source
  type: FieldType;
  metricAggregations?: MetricType[];
  filterComponents: (FilterType | string)[];
  sortable?: boolean;
  digitsNb?: number;
  discardNullValueInAggregations?: boolean;
  displayAs?: NumericMetricDisplay;
}
