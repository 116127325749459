import React, { useState, useCallback } from 'react';
import { UserActionRequiredError, RequestAccessError } from './Errors';

interface UseEmptyStates {
  start: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stop: (error: any) => boolean;
  loading: boolean;
  errorMsg: string | null;
  param: string | null;
  userActionNeeded: boolean;
  requestAccessNeeded: boolean;
}

export function useEmptyStates(): UseEmptyStates {
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [param, setParam] = useState<string | null>(null);
  const [userActionNeeded, setUserActionNeeded] = useState(false);
  const [requestAccessNeeded, setRequestAccessNeeded] = useState(false);
  const [loading, setLoading] = useState(true);
  const start = useCallback(() => {
    setErrorMsg(null);
    setParam(null);
    setUserActionNeeded(false);
    setRequestAccessNeeded(false);
    setLoading(true);
  }, []);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const stop = useCallback((error: any) => {
    setLoading(false);
    if (error) {
      let errorMsg = error.message ? error.message : 'Error';
      if (error instanceof UserActionRequiredError) {
        setUserActionNeeded(true);
      } else if (error instanceof RequestAccessError) {
        errorMsg = error.message ? error.message : 'You are not authorized to see this content';
        setParam(error.redirectUri);
        setRequestAccessNeeded(true);
        setUserActionNeeded(true);
      }
      setErrorMsg(errorMsg);
      return true;
    }
    return false;
  }, []);
  return { start, stop, loading, errorMsg, param, userActionNeeded, requestAccessNeeded };
}

interface Props {
  msg: string;
  param: string | null;
  userActionNeeded: boolean;
  requestAccessNeeded: boolean;
}

export const ErrorMsg: React.FC<Props> = ({ msg, param, userActionNeeded, requestAccessNeeded }) => {
  return (
    // from https://sgbs-playground.fr.world.socgen/editor/ObyP-pz5d
    <>
      <div
        className={`d-flex flex-column h-100 align-items-center ${
          userActionNeeded ? 'text-warning' : 'text-secondary'
        }`}
      >
        <i className="icon icon-md pt-4">info_outline</i>
        <p className="pt-3">{msg}</p>
        {requestAccessNeeded && (
          <div>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://shared.sgmarkets.com/unauthorized/${param ? `?redirectUri=${param}` : ''}`}
              className="btn sgbs-btn-default"
            >
              Request access
            </a>
          </div>
        )}
      </div>
    </>
  );
};
