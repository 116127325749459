/** Color palette recommended for highcharts
 * https://projects.susielu.com/viz-palette?colors=%5B%22#f15f70%22,%22#57161d%22,%22#a9addf%22,%22#4681f8%22,%22#5a31c9%22,%22#c2377f%22,%22#00b5b6%22,%22#ffc8a0%22,%22#7a5629%22,%22#c2450b%22,%22#9972ff%22,%22#d09718%22,%22#f576bb%22,%22#61de85%22,%22#007d7c%22,%22#6e777a%22%5D&backgroundColor=%22#ffffff%22&fontColor=%22#000000%22&mode=%22normal%22
 */
export const categoricalColors = [
  {
    hex: '#f15f70',
    contrastText: '#fff'
  },
  {
    hex: '#57161d',
    contrastText: '#fff'
  },
  {
    hex: '#a9addf',
    contrastText: '#000'
  },
  {
    hex: '#4681f8',
    contrastText: '#fff'
  },
  {
    hex: '#5a31c9',
    contrastText: '#fff'
  },
  {
    hex: '#c2377f',
    contrastText: '#fff'
  },
  {
    hex: '#00b5b6',
    contrastText: '#fff'
  },
  {
    hex: '#ffc8a0',
    contrastText: '#000'
  },
  {
    hex: '#7a5629',
    contrastText: '#fff'
  },
  {
    hex: '#c2450b',
    contrastText: '#fff'
  },
  {
    hex: '#9972ff',
    contrastText: '#fff'
  },
  {
    hex: '#d09718',
    contrastText: '#000'
  },
  {
    hex: '#f576bb',
    contrastText: '#000'
  },
  {
    hex: '#61de85',
    contrastText: '#000'
  },
  {
    hex: '#007d7c',
    contrastText: '#fff'
  },
  {
    hex: '#6e777a',
    contrastText: '#fff'
  }
];

export const gradientColors = [
  '#d4eeef',
  '#a3dee5',
  '#72d2d5',
  '#4fd2bd',
  '#22d297',
  '#0cbb92',
  '#0c96a7',
  '#0d50c7',
  '#0a309d',
  '#07215b'
];

export const getContrastTextColor = (color: string): string | undefined => {
  const obj = categoricalColors.find(x => x.hex === color);
  if (obj) {
    return obj.contrastText;
  }
  return undefined;
};
